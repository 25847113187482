<template>
  <div class="elv-sources-header">
    <p class="elv-sources-header-title">
      {{ props.title }} <span>({{ props.total }})</span>
    </p>
    <div v-if="props.showButton" class="elv-sources-header-button" @click="onClickAdd">
      <SvgIcon :name="props.buttonIcon" width="16" height="16" />
      <span>{{ props.buttonText }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  title: {
    type: String,
    required: true
  },
  total: {
    type: Number,
    default: 0
  },
  buttonIcon: {
    type: String,
    default: 'source-add'
  },
  buttonText: {
    type: String,
    default: ''
  },
  showButton: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(['add'])

const onClickAdd = () => {
  emit('add', props.title)
}
</script>

<style lang="scss" scoped>
.elv-sources-header {
  width: 870px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 16px;
}

.elv-sources-header-title {
  font-family: 'Plus Jakarta Sans';
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #0e0f11;
  margin: 0px;
}

.elv-sources-header-button {
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  color: #1753eb;
  border: 1px solid #c8d6fa;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.04));
  border-radius: 24px;
  transition: all 0.1s;
  cursor: pointer;

  span {
    margin-left: 7px;
  }

  svg {
    fill: #1753eb;
  }

  &:hover {
    color: #1343bf;
    border: 1px solid #5e85eb;
  }
}
</style>
