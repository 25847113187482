<template>
  <el-table
    :data="dataList"
    border
    class="elv-sources-table"
    header-cell-class-name="elv-sources-table-header__cell"
    header-row-class-name="elv-sources-table-header"
    row-class-name="elv-sources-table-row"
    cell-class-name="elv-sources-table-row__cell"
    @sort-change="onSortChange"
  >
    <el-table-column :label="t('common.name')" :width="columnNameWidth" :resizable="false" sortable="custom">
      <template #header="{ column }">
        <SvgIcon
          v-if="column.order"
          width="12"
          height="12"
          name="source-sort"
          :class="[column.order === 'ascending' ? 'ascending' : 'descending']"
        />
        {{ t('common.name') }}
      </template>
      <template #default="{ row }">
        <div class="elv-sources-table-row__cell-AccountName">{{ row.sourceName }}</div>
      </template>
    </el-table-column>
    <el-table-column
      v-if="props.type !== 'API' && props.type !== 'Files'"
      prop="purpose"
      label="Type"
      width="120"
      :resizable="false"
      sortable="custom"
    >
      <template #header="{ column }">
        <SvgIcon
          v-if="column.order"
          width="12"
          height="12"
          name="source-sort"
          :class="[column.order === 'ascending' ? 'ascending' : 'descending']"
        />
        {{ t('common.type') }}
      </template>
      <template #default="{ row }">
        {{ capitalize(row?.purpose) }}
      </template>
    </el-table-column>
    <el-table-column
      v-if="props.type === 'Wallets'"
      prop="address"
      :label="t('common.address')"
      width="214px"
      :resizable="false"
    >
      <template #default="{ row }">
        <div class="elv-sources-table-row__cell-address">
          <img :src="row.platform?.logo" :alt="row.platform?.name" class="elv-sources-table-row__cell-address_logo" />
          <p :data-clipboard-text="row.address">{{ utils.formatInputAddress(row.address) }}</p>
          <SvgIcon name="sources-copy" width="12" height="12" @click="onClickedCopy(row.address)" />
        </div>
      </template>
    </el-table-column>
    <el-table-column
      v-if="props.type === 'Exchanges'"
      prop="exchange"
      :label="t('common.exchange')"
      width="214px"
      :resizable="false"
    >
      <template #default="{ row }">
        <div class="elv-sources-table-row__cell-exchange">
          <img :src="row.platform?.logo" :alt="row.platform?.name" class="elv-sources-table-row__cell-exchange_logo" />
          <p>{{ row.platform?.name }}</p>
        </div>
      </template>
    </el-table-column>

    <el-table-column
      v-if="props.type === 'Files'"
      prop="filename"
      :label="t('common.fileName')"
      width="264px"
      :resizable="false"
    >
      <template #default="{ row }">
        <div class="elv-sources-table-row__cell-Filename">
          <SvgIcon name="sources-csv" width="20" height="20" />
          <p>{{ row?.csvFile?.name }}</p>
        </div>
      </template>
    </el-table-column>

    <el-table-column
      prop="transactionCount"
      align="right"
      :label="t('report.Transactions')"
      width="110"
      :resizable="false"
      sortable="custom"
    >
      <template #header="{ column }">
        <SvgIcon
          v-if="column.order"
          width="12"
          height="12"
          name="source-sort"
          :class="[column.order === 'ascending' ? 'ascending' : 'descending']"
        />
        {{ t('report.Transactions') }}
      </template>
      <template #default="{ row }">
        <span v-if="row.syncStatus === 'WAITING' || row.syncStatus === 'DOING'">···</span>
        <span v-else>{{ row.transactionCount !== null ? row.transactionCount : '-' }}</span>
      </template>
    </el-table-column>
    <el-table-column width="200" :resizable="false" sortable="custom">
      <template #header="{ column }">
        <SvgIcon
          v-if="column.order"
          width="12"
          height="12"
          name="source-sort"
          :class="[column.order === 'ascending' ? 'ascending' : 'descending']"
        />
        {{ props.type !== 'Files' ? t('report.dataCutoffTime') : t('report.added') }}
      </template>
      <template #default="{ row }">
        <div class="elv-sources-table-row__cell-date">
          <template v-if="props.type !== 'API' && row.dateEndpoint && row.syncStatus === 'SUCCESS'">
            <SvgIcon name="sources-sync-success" width="14" height="14" />
            <!-- <SvgIcon name="sources-sync-warning" width="14" height="14" /> -->
            <p>{{ row.dateEndpoint ? dayjs(row.dateEndpoint).format('YYYY/MM/DD') : '-' }}</p>
          </template>

          <template v-if="props.type !== 'API' && (row.syncStatus === 'WAITING' || row.syncStatus === 'DOING')">
            <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />
            <p>{{ t('common.syncing') }}</p>
          </template>

          <template v-if="props.type !== 'API' && row.syncStatus === 'DELETING'">
            <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />
            <p>{{ t('report.deleting') }}</p>
          </template>

          <template v-if="props.type !== 'API' && row.syncStatus === 'FAILED'">
            <SvgIcon name="sources-sync-error" width="14" height="14" />
            <p>{{ t('common.syncFailed') }}</p>
          </template>
        </div>
      </template>
    </el-table-column>
    <el-table-column :resizable="false">
      <template #default="{ row }">
        <div v-if="props.type !== 'API'" class="elv-sources-table-row__cell-button">
          <SvgIcon
            name="sources-edit"
            :class="{ 'is-disabled': row.syncStatus === 'DELETING' }"
            width="20"
            height="20"
            @click="onEdit(row)"
          />
          <SvgIcon
            :class="{ 'is-disabled': row.syncStatus === 'DELETING' }"
            name="sources-delete"
            width="20"
            height="20"
            @click="onDelete(row)"
          />
        </div>
      </template>
    </el-table-column>
  </el-table>
  <div v-if="isShowMore" class="elv-sources-table-button__more" @click="onLoadMore">{{ t('button.loadMore') }}</div>

  <DeleteSource ref="deleteSourceRef" :type="type" :item-data="currentItemData" />

  <WalletDialog ref="walletDialogRef" :title="t('report.editWallet')" model="edit" :current-data="currentItemData" />

  <ExchangeDialog
    ref="exchangeDialogRef"
    :title="t('report.editExchange')"
    model="edit"
    :current-data="currentItemData"
  />

  <UploadCSVDialog ref="uploadCSVDialogRef" :title="t('report.editCSV')" model="edit" :current-data="currentItemData" />
</template>

<script lang="ts" setup>
import dayjs from 'dayjs'
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import ReportsApi from '@/api/ReportsApi'
import useClipboard from 'vue-clipboard3'
import WalletDialog from './WalletDialog.vue'
import DeleteSource from './DeleteSource.vue'
import { capitalize, orderBy } from 'lodash-es'
import ExchangeDialog from './ExchangeDialog.vue'
import UploadCSVDialog from './UploadCSVDialog.vue'
import { useReportStore } from '@/stores/modules/reports/index'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)
const { t } = useI18n()
const reportStore = useReportStore()
const { toClipboard } = useClipboard()

const props = defineProps({
  type: {
    type: String,
    default: 'Wallets'
  },
  dataList: {
    type: Array,
    default: () => {
      return []
    }
  }
})

const deleteSourceRef = ref()
const walletDialogRef = ref()
const exchangeDialogRef = ref()
const uploadCSVDialogRef = ref()
const currentItemData = ref({})
const sortByData: any = ref({ key: 'dateEndpoint', order: 'desc' })
const page = ref(1)

const dataList = computed(() => {
  const newList = orderBy(props.dataList, [sortByData.value.key], [sortByData.value.order])
  return sortByData.value.order !== '' ? newList.slice(0, page.value * 10) : props.dataList.slice(0, page.value * 10)
})

const currentEntityPermission = computed(() => {
  return reportStore.entityPermission()
})

const isShowMore = computed(() => {
  return page.value * 10 < props.dataList.length
})

const borderRadius = computed(() => {
  return isShowMore.value ? '0' : '4px'
})

const borderBottom = computed(() => {
  return isShowMore.value ? '0px' : '1px'
})

const columnNameWidth = computed(() => {
  if (props.type === 'API') {
    return '473'
  }
  if (props.type === 'Files') {
    return '210'
  }
  return '140'
})

const onLoadMore = () => {
  // 判断页面边界
  if (page.value * 10 >= props.dataList.length) return
  page.value += 1
}

const onClickedCopy = async (msg: string) => {
  try {
    await toClipboard(msg)
    ElMessage.success(t('common.copied'))
  } catch (e) {
    console.error(e)
  }
}

const onEdit = async (row: any) => {
  try {
    if (!currentEntityPermission.value?.dataSource?.update) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    if (row.syncStatus === 'DELETING') return
    switch (props.type) {
      case 'Wallets':
        walletDialogRef.value?.onCheckWalletDialog()
        break
      case 'Exchanges':
        exchangeDialogRef.value?.onCheckExchangeDialog()
        break
      default:
        uploadCSVDialogRef.value?.onCheckUploadDialog()
        break
    }
    const { data } = await ReportsApi.getSourceDetail(row.entityId, row.sourceId)
    currentItemData.value = data
    if (props.type === 'Files') {
      currentItemData.value = row
    }
  } catch (error) {
    console.error(error)
  }
}

const onSortChange = (e: any) => {
  // eslint-disable-next-line no-nested-ternary
  const key = e.prop ? e.prop : e.column.label === 'Name' ? 'sourceName' : 'dateEndpoint'
  sortByData.value = {
    key,
    // eslint-disable-next-line no-nested-ternary
    order: e.order === 'ascending' ? 'asc' : e.order === 'descending' ? 'desc' : ''
  }
}

const onDelete = (row: any) => {
  if (!currentEntityPermission.value?.dataSource?.delete) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (row.syncStatus === 'DELETING') return
  currentItemData.value = row
  deleteSourceRef.value?.onCheckDeleteDialog()
}
</script>
<style lang="scss">
.elv-sources-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  width: 870px;
  border: 1px solid #d4dce6;
  box-sizing: border-box;
  border-radius: 4px;
  border-bottom-left-radius: v-bind('borderRadius');
  border-bottom-right-radius: v-bind('borderRadius');
  border-bottom-width: v-bind('borderBottom');

  .el-table__inner-wrapper {
    width: 870px;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.el-table--border {
    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__body-wrapper {
    .el-scrollbar__view {
      width: 100%;
      margin-bottom: 0px !important;
    }
  }

  .el-table__border-left-patch {
    width: 0;
  }

  .elv-sources-table-header {
    background: #f9fbfe;

    .elv-sources-table-header__cell {
      background: #f9fbfe;
      height: 26px;
      box-sizing: border-box;
      padding: 6px 0px;
      border-right-color: #e4e7eb;

      &:last-child {
        border-right: 0px;
      }

      &.is-sortable:hover {
        .cell {
          color: #677d93;

          svg {
            fill: #677d93;
          }
        }
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        color: #9ba8b5;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;

        svg {
          fill: #9ba8b5;
          margin-right: 4px;

          &.descending {
            transform: rotate(180deg);
          }
        }

        .caret-wrapper {
          display: none;
        }
      }
    }
  }

  .elv-sources-table-row:last-child {
    .elv-sources-table-row__cell {
      border-bottom: 0px;
    }
  }

  .elv-sources-table-row__cell {
    padding: 0;
    height: 42px;

    &:last-child {
      border-right: 0px;
    }

    .cell {
      padding: 0 10px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
    }

    .elv-sources-table-row__cell-AccountName {
      font-weight: 700;
    }

    .elv-sources-table-row__cell-address {
      display: flex;
      align-items: center;

      .elv-sources-table-row__cell-address_logo {
        width: 20px;
        height: 20px;
        background: #f6f6f6;
        border-radius: 50%;
        display: block;
        margin-right: 8px;
      }

      p {
        font-family: 'Barlow';
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #0e0f11;
        margin-right: 4px;
      }

      svg {
        cursor: pointer;
      }
    }

    .elv-sources-table-row__cell-exchange {
      display: flex;
      align-items: center;

      .elv-sources-table-row__cell-exchange_logo {
        width: 20px;
        height: 20px;
        background: #f6f6f6;
        border-radius: 4px;
        display: block;
        margin-right: 8px;
      }

      p {
        font-family: 'Barlow';
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #0e0f11;
      }
    }

    .elv-sources-table-row__cell-Filename {
      display: flex;
      align-items: center;

      p {
        font-family: 'Barlow';
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #0e0f11;
        margin-left: 8px;
      }
    }

    .elv-sources-table-row__cell-date {
      display: flex;
      align-items: center;

      svg {
        margin-right: 4px;
      }

      img {
        display: block;
        margin-right: 4px;
        width: 14px;
        height: 14px;
        animation: loading-rotate 2s linear infinite;
      }

      p {
        font-family: 'Barlow';
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #0e0f11;
      }
    }

    .elv-sources-table-row__cell-button {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        cursor: pointer;
        fill: #838d95;

        &.is-disabled {
          cursor: not-allowed !important;
          fill: #edf0f3 !important;
        }

        &:first-of-type {
          margin-right: 10px;
        }

        &:hover {
          fill: #1e2024;
        }
      }
    }
  }
}

.elv-sources-table-button__more {
  width: 870px;
  height: 46px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Plus Jakarta Sans';
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #636b75;
  border: 1px solid #d4dce6;
  border-top: 1px solid #edf0f3;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
}
</style>
