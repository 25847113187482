<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-loading="loading" class="elv-sources-container">
    <el-scrollbar class="elv-sources-content-wrap">
      <div v-if="showSyncLoading" class="elv-sources-sync-container">
        <div v-if="!syncComplete" class="elv-sources-sync-loading">
          <div>
            <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />
            <span>{{
              t('report.syncingSources', {
                progress: reportStore.sourceDoingCount,
                total:
                  currentProjectSourceSyncList?.list?.length < reportStore.sourceDoingCount
                    ? reportStore.sourceDoingCount
                    : currentProjectSourceSyncList?.list?.length
              })
            }}</span>
          </div>
          <span v-if="syncTimeout">{{ t('message.sourceSyncTimeoutInfo') }}</span>
        </div>
        <div v-else class="elv-sources-sync-success">
          <div class="elv-sources-sync-success__info">
            <SvgIcon name="sources-sync-done" width="18" height="18" />
            <p class="elv-sources-sync-success__info-text">
              {{ t('report.syncTransactionsComplete') }}<span>{{ currentProjectSourceSyncTotal }}</span
              >{{ t('report.syncTransactionsTotal') }}
            </p>
            <p class="elv-sources-sync-success__info-button" @click="onJumpTransactionsPage">
              {{ t('button.clickView') }}
            </p>
          </div>
          <SvgIcon
            class="elv-sources-sync-success__close"
            name="close"
            width="18"
            height="18"
            @click="checkSyncLoading(false)"
          />
        </div>
      </div>
      <Header
        :title="t('common.wallets')"
        :button-text="t('button.addWallet')"
        :total="sourceList.wallet.length"
        :show-button="sourceList.wallet.length > 0"
        @add="onClickAdd"
      />
      <Table v-if="sourceList.wallet.length" type="Wallets" :data-list="sourceList.wallet" />
      <NoData v-else :type="t('common.wallets')" @add="onClickAdd" />

      <Header
        :title="t('common.exchanges')"
        :button-text="t('button.addExchange')"
        :total="sourceList.exchange.length"
        :show-button="sourceList.exchange.length > 0"
        @add="onClickAdd"
      />
      <Table v-if="sourceList.exchange.length" type="Exchanges" :data-list="sourceList.exchange" />
      <NoData v-else :type="t('common.exchanges')" @add="onClickAdd" />

      <Header
        :title="t('common.csvFiles')"
        :button-text="t('button.upLoadCSV')"
        :total="sourceList.csv.length"
        button-icon="source-upload"
        :show-button="sourceList.csv.length > 0"
        @add="onClickAdd"
      />
      <Table v-if="sourceList.csv.length" type="Files" :data-list="sourceList.csv" />
      <NoData v-else type="Files" @add="onClickAdd" />

      <template v-if="sourceList.api.length">
        <Header :title="t('common.customizedAPI')" :show-button="false" :total="sourceList.api.length" />
        <Table type="API" :data-list="sourceList.api" />
      </template>
    </el-scrollbar>
  </div>

  <WalletDialog ref="walletDialogRef" :title="t('button.addWallet')" />

  <ExchangeDialog ref="exchangeDialogRef" :title="t('button.addExchange')" />

  <UploadCSVDialog ref="uploadCSVDialogRef" :title="t('button.upLoadCSV')" />

  <ReviewTeachingDialog ref="reviewTeachDialogRef" :imported-total="currentProjectSourceSyncTotal" />
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import { emitter } from '@/lib/mitt'
import { ElMessage } from 'element-plus'
import Table from './components/Table.vue'
import NoData from './components/NoData.vue'
import Header from './components/Header.vue'
import { useGlobalStore } from '@/stores/modules/global'
import WalletDialog from './components/WalletDialog.vue'
import ExchangeDialog from './components/ExchangeDialog.vue'
import UploadCSVDialog from './components/UploadCSVDialog.vue'
import { useReportStore } from '@/stores/modules/reports/index'
import ReviewTeachingDialog from './components/ReviewTeachingDialog.vue'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const globalStore = useGlobalStore()
const reportStore = useReportStore()
const { sourceList } = storeToRefs(reportStore)

const walletDialogRef = ref()
const exchangeDialogRef = ref()
const uploadCSVDialogRef = ref()
const reviewTeachDialogRef = ref()
const loading = ref(true)
const showSyncLoading = ref(false)
const syncComplete = ref(false)
const syncTimer: any = ref(null)
const sourceListTimer: any = ref(null)
const synchronizationProgress = ref(0) // 同步进度

const entityId = computed(() => {
  return Number(route.params?.entityId)
})

const contentHeight = computed(() => {
  return globalStore.showUpGradeInfo ? `calc(100vh - 179px)` : `calc(100vh - 148px)`
})

const currentEntityPermission = computed(() => {
  return reportStore.entityPermission()
})

const currentProjectSourceSyncList = computed(() => {
  return reportStore.sourceSyncList.find((item: any) => item.entityId === entityId.value)
})

const syncTimeout = computed(() => {
  if (!showSyncLoading.value && syncComplete.value) return false
  const syncTime = dayjs(currentProjectSourceSyncList.value?.list?.[0]?.createdAt)
  const nowTime = dayjs()
  const diffTime = nowTime.diff(syncTime, 'minute')
  return diffTime > 3
})

const sourceDataList = computed(() => {
  const list = [
    ...reportStore.sourceList.wallet,
    ...reportStore.sourceList.exchange,
    ...reportStore.sourceList.csv,
    ...reportStore.sourceList.api
  ]

  return list
})
const currentProjectSourceSyncTotal = computed(() => {
  const sourceSyncList = sourceDataList.value.filter((item: any) => {
    return currentProjectSourceSyncList?.value?.list.some((sourceItem: any) => {
      return sourceItem.sourceName === item.sourceName
    })
  })
  const SyncTotal = sourceSyncList.reduce((total: any, item: any) => {
    return total + item.transactionCount
  }, 0)
  return (SyncTotal === 0 ? currentProjectSourceSyncList.value?.syncTotal : SyncTotal) || 0
})

const onClickAdd = (type: string) => {
  if (!currentEntityPermission.value?.dataSource?.create) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  switch (type) {
    case t('common.wallets'):
      walletDialogRef.value?.onCheckWalletDialog()
      break
    case t('common.exchanges'):
      exchangeDialogRef.value?.onCheckExchangeDialog()
      break
    default:
      uploadCSVDialogRef.value?.onCheckUploadDialog()
      break
  }
}
/**
 * @description: 切换显示同步提示
 * @param {boolean} state
 */
const checkSyncLoading = (state: boolean) => {
  showSyncLoading.value = state
  syncComplete.value = false
  const isInSourceSyncList = reportStore.sourceSyncList.find((e: any) => {
    return e.entityId === entityId.value
  })
  if (isInSourceSyncList) {
    if (reportStore.sourceDoingCount < 1) {
      reportStore.sourceSyncList.splice(
        reportStore.sourceSyncList.findIndex((item: any) => item.entityId === entityId.value),
        1
      )
    } else {
      reportStore.editSourceSync(entityId.value, false, {})
    }
  }
}

const onJumpTransactionsPage = () => {
  router.push({ name: 'reports-transactions' })
}

const onStartSync = async (onLoad: boolean = true) => {
  const isInSourceSyncList = reportStore.sourceSyncList.find((e: any) => {
    return e.entityId === entityId.value
  })
  showSyncLoading.value = true && isInSourceSyncList?.status
  if (syncTimer.value === null && sourceDataList.value.length) {
    if (onLoad) {
      await reportStore.fetchSourceSyncStatus(entityId.value)
    }
    syncComplete.value = reportStore.sourceDoingCount < 1
    if (synchronizationProgress.value !== reportStore.sourceDoingCount) {
      synchronizationProgress.value = reportStore.sourceDoingCount
      reportStore.fetchSourceList(entityId.value)
    }
    syncTimer.value = setInterval(async () => {
      await reportStore.fetchSourceSyncStatus(entityId.value)
      if (synchronizationProgress.value !== reportStore.sourceDoingCount) {
        synchronizationProgress.value = reportStore.sourceDoingCount
        await reportStore.fetchSourceList(entityId.value)
      }
      syncComplete.value = reportStore.sourceDoingCount < 1
      if (reportStore.sourceDoingCount < 1) {
        // eslint-disable-next-line no-unused-expressions
        syncTimer.value && clearInterval(syncTimer.value)
        syncTimer.value = null
        synchronizationProgress.value = 0
        reportStore.fetchCurrencyList(entityId.value)
        setTimeout(() => {
          if (sourceDataList.value.length) {
            reportStore.editSourceSync(entityId.value, true, {}, currentProjectSourceSyncTotal.value)
          }
        }, 1000)
        if (reportStore.entityDetail.guideStep < 2) {
          await reportStore.fetchEntityDetail(entityId.value)
          if (
            !walletDialogRef.value?.showWalletDialog &&
            !exchangeDialogRef.value?.showExchangeDialog &&
            !uploadCSVDialogRef.value?.showUploadDialog
          ) {
            reviewTeachDialogRef.value?.onCheckReviewTeachDialog()
          }
        }
      }
    }, 5000)
  } else {
    if (sourceDataList.value.length) {
      await reportStore.fetchSourceSyncStatus(entityId.value)
    }
    if (synchronizationProgress.value !== reportStore.sourceDoingCount) {
      synchronizationProgress.value = reportStore.sourceDoingCount
      await reportStore.fetchSourceList(entityId.value)
    }
  }
}

emitter.on('onStartSync', onStartSync)

const onFetchIntervalSourceList = () => {
  if (sourceListTimer.value === null) {
    sourceListTimer.value = setInterval(async () => {
      await reportStore.fetchSourceList(entityId.value)
      const isDeleting = sourceDataList.value.some((item: any) => {
        return item.syncStatus === 'DELETING'
      })
      if (!isDeleting) {
        // eslint-disable-next-line no-unused-expressions
        sourceListTimer.value && clearInterval(sourceListTimer.value)
        sourceListTimer.value = null
        reportStore.fetchCurrencyList(entityId.value)
      }
    }, 8000)
  }
}
emitter.on('onFetchIntervalSourceList', onFetchIntervalSourceList)

// onMounted(async () => {})

watch(
  () => route.query,
  async () => {
    if (route.name === 'reports-sources-detail') {
      try {
        reportStore.sourceList = {
          wallet: [],
          exchange: [],
          csv: [],
          api: []
        }
        await reportStore.fetchSourceList(entityId.value)
        await reportStore.fetchSourceSyncStatus(entityId.value)
        showSyncLoading.value = reportStore.sourceDoingCount > 0
        synchronizationProgress.value = reportStore.sourceDoingCount
        loading.value = false
        if (reportStore.sourceDoingCount > 0) {
          onStartSync(false)
        } else {
          const isInSourceSyncList = reportStore.sourceSyncList.find((e: any) => {
            return e.entityId === entityId.value
          })
          if (isInSourceSyncList && isInSourceSyncList?.status) {
            showSyncLoading.value = true
            syncComplete.value = true
          }
        }
        const isDeleting = sourceDataList.value.some((item: any) => {
          return item.syncStatus === 'DELETING'
        })
        if (isDeleting) {
          onFetchIntervalSourceList()
        }
      } catch (error) {
        loading.value = false
        console.log(error)
      }
    }
  },
  { immediate: true }
)

onBeforeUnmount(() => {
  emitter.off('onStartSync', onStartSync)
  emitter.off('onFetchIntervalSourceList', onFetchIntervalSourceList)
  // eslint-disable-next-line no-unused-expressions
  syncTimer.value && clearInterval(syncTimer.value)
  // eslint-disable-next-line no-unused-expressions
  sourceListTimer.value && clearInterval(sourceListTimer.value)
})
</script>

<style lang="scss" scoped>
.elv-sources-container {
  padding-left: 20px;
  height: v-bind('contentHeight');
}

:deep(.elv-sources-content-wrap.el-scrollbar) {
  .el-scrollbar__view {
    margin-bottom: 20px;
  }
}

.elv-sources-sync-container {
  display: flex;
  align-items: center;
  padding: 10px 8px;
  width: 870px;
  height: 38px;
  box-sizing: border-box;
  background: #eef4fb;
  border: 1px solid #dae1e8;
  border-radius: 2px;
  margin-top: 16px;

  .elv-sources-sync-loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 18px;
        height: 18px;
        margin-right: 8px;
        animation: loading-rotate 2s linear infinite;
      }

      span {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #1e2024;
      }
    }

    span {
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      color: #636b75;
    }
  }

  .elv-sources-sync-success {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .elv-sources-sync-success__info {
      display: flex;
      align-items: center;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #1e2024;

      .elv-sources-sync-success__info-text {
        margin: 0;
        margin-left: 8px;

        span {
          font-weight: 700;
          color: #1e2024;
          text-decoration: none;
          cursor: default;
        }
      }

      .elv-sources-sync-success__info-button {
        color: #1753eb;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .elv-sources-sync-success__close {
      cursor: pointer;
    }
  }
}
@keyframes loading-rotate {
  to {
    transform: rotate(360deg);
  }
}
</style>
