<template>
  <el-dialog
    v-model="showTeachingDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-source-exchange-dialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-source-exchange-dialog-header__title"></h4>
    </template>
    <h5>👍{{ t('report.wellDone') }},{{ userGlobalStore.user?.name }}!</h5>
    <p>
      {{ t('message.sourceImportedSuccess', { total: props.importedTotal }) }}
    </p>
    <div id="elv-source-teach-video-container"></div>
    <template #footer>
      <elv-button height="44" width="229" round @click="onJumpTransactionsPage">{{ t('button.goReview') }}</elv-button>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import Player from 'xgplayer'
import { useI18n } from 'vue-i18n'
import 'xgplayer/dist/index.min.css'
import { useUserGlobalStore } from '@/stores/modules/global/user'

const props = defineProps({
  importedTotal: {
    type: Number,
    default: 0
  }
})

const router = useRouter()
const { t, locale } = useI18n()
const userGlobalStore = useUserGlobalStore()

const showTeachingDialog = ref(false)

// 切换删除弹窗展示
const onCheckReviewTeachDialog = () => {
  showTeachingDialog.value = !showTeachingDialog.value
}

const onJumpTransactionsPage = async () => {
  router.push({ name: 'reports-transactions-for-review' })
}

watch(
  () => showTeachingDialog.value,
  () => {
    if (showTeachingDialog.value) {
      nextTick(() => {
        // eslint-disable-next-line no-new
        new Player({
          lang: locale.value,
          id: 'elv-source-teach-video-container',
          url: 'https://static.elven.com/video/review-transcations.mp4',
          height: '290px',
          width: '100%',
          defaultPlaybackRate: 1,
          theme: '#1753EB'
        })
      })
    }
  },
  { immediate: true }
)
defineExpose({ onCheckReviewTeachDialog })
</script>

<style lang="scss">
.elv-source-exchange-dialog {
  width: 620px;
  min-height: 565px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border: 0px;
  }

  .el-dialog__body {
    padding: 18px 24px 44px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    h5 {
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 27px;
    }

    p {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 23px;
    }
  }

  .el-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;

    .el-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: fit-content;
      background: #1753eb;
      border-radius: 22px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
      border: 0px;
      padding: 0 35px;
    }
  }
}
</style>
