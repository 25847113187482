<template>
  <el-dialog
    v-model="showExchangeDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-source-exchange-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-source-exchange-dialog-header__title">
        {{ props.title }}
      </h4>
    </template>
    <el-form ref="exchangeFormRef" :model="exchangeForm" :rules="rules" label-position="top">
      <el-form-item :label="t('common.type')" prop="purpose">
        <el-select
          v-model="exchangeForm.purpose"
          placeholder=" "
          popper-class="elv-source-dialog-popper"
          :disabled="props.model !== 'add'"
        >
          <el-option
            v-for="(item, index) in reportTransactionType"
            :key="index"
            :label="transformI18n(item.label)"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="t('common.name')" prop="sourceName">
        <el-input v-model="exchangeForm.sourceName" :placeholder="t('report.sourceName')" />
      </el-form-item>
      <el-form-item :label="t('common.exchange')" prop="platformId">
        <el-select
          v-model="exchangeForm.platformId"
          placeholder=" "
          popper-class="elv-source-dialog-popper"
          :disabled="props.model !== 'add'"
        >
          <el-option
            v-for="(item, index) in exchangeList"
            :key="index"
            :label="item.name"
            :value="item.platformId"
            :disabled="props.model !== 'add' || !item.pro"
          >
            <div class="elv-source-dialog-exchange-option">
              <img :src="item.logo" :alt="item.name" />
              <p>
                {{ item.name }}<span v-if="!item.pro">{{ t('common.soon') }}</span>
              </p>
            </div>
          </el-option>
          <template v-if="exchangeForm.platformId" #prefix>
            <img
              :src="selectedExchange(exchangeForm.platformId)?.logo"
              :alt="selectedExchange(exchangeForm.platformId)?.name"
              class="elv-source-dialog-exchange-prefix"
            />
          </template>
        </el-select>
      </el-form-item>
      <el-form-item label="API Key" prop="apiKey">
        <el-input v-model.trim="exchangeForm.apiKey" :placeholder="t('report.key')" />
      </el-form-item>
      <el-form-item label="API Secret" prop="apiSecret">
        <el-input v-model.trim="exchangeForm.apiSecret" :placeholder="t('report.secret')" />
      </el-form-item>
      <el-form-item v-if="Number(exchangeForm.platformId) === 13" :label="t('report.passphrase')" prop="passphrase">
        <el-input v-model.trim="exchangeForm.passphrase" :placeholder="t('report.passphrase')" />
      </el-form-item>
      <div class="elv-source-data-select-content">
        <el-form-item :label="t('report.transactionDataOption')" prop="transactionOption.operator">
          <el-select
            v-model="exchangeForm.transactionOption.operator"
            placeholder=" "
            popper-class="elv-source-dialog-popper"
            :class="{
              'elv-source-data-short-select':
                exchangeForm.transactionOption.operator !== 'ALL' &&
                exchangeForm.transactionOption.operator !== 'BETWEEN',
              'elv-source-data-shorter-select': exchangeForm.transactionOption.operator === 'BETWEEN'
            }"
            @change="onChangeOperator"
          >
            <el-option
              v-for="(item, index) in transactionDataOptions"
              :key="index"
              :label="transformI18n(item.label)"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="exchangeForm.transactionOption.operator !== 'ALL'"
          class="elv-source-exchange-form-item-date"
          prop="value"
        >
          <div
            ref="dateStartSelectRef"
            v-click-outside="onCloseStartDateTime"
            class="elv-source-exchange-date-select"
            :class="{
              'is-short': exchangeForm.transactionOption.operator === 'BETWEEN',
              'is-focus': visibleStartPopover
            }"
            @click="onOpenStartDateTime()"
          >
            <div v-if="exchangeForm.transactionOption.value[0]" class="elv-source-exchange-date-time-value">
              <span>{{
                exchangeForm.transactionOption.value[0]
                  ? utils.dateFormatting(exchangeForm.transactionOption.value[0], 'date', '-')
                  : ''
              }}</span>
            </div>
            <SvgIcon name="events-arrow" width="20" height="20" :style="{ transform: startArrowRotate }" />
          </div>

          <el-date-picker
            ref="datePickerStartPopoverRef"
            v-model="exchangeForm.transactionOption.value[0]"
            placement="bottom-start"
            popper-class="elv-source-exchange-date-popper"
            :virtual-ref="dateStartSelectRef"
            virtual-triggering
            type="date"
            value-format="YYYY-MM-DD"
            :clearable="false"
            @change="onChangeDate('start')"
          />
          <template v-if="exchangeForm.transactionOption.operator === 'BETWEEN'">
            <span class="elv-source-exchange-date-scope">{{ t('common.to') }}</span>
            <div
              ref="dateEndSelectRef"
              v-click-outside="onCloseEndDateTime"
              class="elv-source-exchange-date-select is-short"
              :class="{ 'is-focus': visibleEndPopover }"
              @click="onOpenEndDateTime"
            >
              <div v-if="exchangeForm.transactionOption.value[1]" class="elv-source-exchange-date-time-value">
                <span>{{
                  exchangeForm.transactionOption.value[1]
                    ? utils.dateFormatting(exchangeForm.transactionOption.value[1], 'date', '-')
                    : ''
                }}</span>
              </div>
              <SvgIcon name="events-arrow" width="20" height="20" :style="{ transform: endArrowRotate }" />
            </div>

            <el-date-picker
              ref="datePickerEndPopoverRef"
              v-model="exchangeForm.transactionOption.value[1]"
              placement="bottom-start"
              popper-class="elv-source-exchange-date-popper"
              :virtual-ref="dateEndSelectRef"
              virtual-triggering
              type="date"
              value-format="YYYY-MM-DD"
              :clearable="false"
              @change="onChangeDate('end')"
            />
          </template>
        </el-form-item>
      </div>
    </el-form>

    <template #footer>
      <elv-button
        type="primary"
        round
        height="44"
        width="113"
        class="elv-source-exchange-dialog-footer__delete"
        :loading="submitLoading"
        :disabled="disabledSave"
        @click="onClickConnect"
        >{{ props.model === 'add' ? t('button.connect') : t('button.save') }}</elv-button
      >
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import { emitter } from '@/lib/mitt'
import ReportsApi from '@/api/ReportsApi'
import { transformI18n } from '@/i18n/index'
import { useGlobalStore } from '@/stores/modules/global'
import { find, pick, isEqual, cloneDeep } from 'lodash-es'
import type { FormInstance, FormRules } from 'element-plus'
import { useReportStore } from '@/stores/modules/reports/index'
import { ElMessage, ClickOutside as vClickOutside } from 'element-plus'
import { reportTransactionType, transactionDataOptions } from '@/config/reports'

const props = defineProps({
  model: {
    type: String,
    default: 'add'
  },
  title: {
    type: String,
    default: ''
  },
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { t } = useI18n()
const reportStore = useReportStore()
const globalStore = useGlobalStore()
const { exchangeList } = storeToRefs(globalStore)

const selectedExchange = computed(() => (platformId: any) => {
  return find(exchangeList.value, { platformId: Number(platformId) })
})

const exchangeForm = reactive({
  type: 'EXCHANGE',
  purpose: 'OPERATING',
  platformId: '',
  sourceName: '',
  apiKey: '',
  apiSecret: '',
  passphrase: '',
  transactionOption: {
    operator: 'ALL',
    value: []
  }
})

const route = useRoute()
const datePickerStartPopoverRef = ref()
const datePickerEndPopoverRef = ref()
const dateStartSelectRef = ref()
const dateEndSelectRef = ref()
const submitLoading = ref(false)
const showExchangeDialog = ref(false)
const visibleStartPopover = ref(false)
const visibleEndPopover = ref(false)
const startArrowRotate = ref('rotate(90deg)')
const endArrowRotate = ref('rotate(90deg)')
const exchangeFormRef = ref<FormInstance>()

const rules = reactive<FormRules>({
  purpose: {
    required: true,
    trigger: 'blur',
    message: 'Source Type is required'
  },
  sourceName: {
    required: true,
    trigger: 'blur',
    message: 'Source Name is required'
  },
  platformId: {
    required: true,
    trigger: 'blur',
    message: 'Exchange is required'
  },
  apiKey: {
    required: true,
    trigger: 'blur',
    message: 'API Key is required'
  },
  apiSecret: {
    required: true,
    trigger: 'blur',
    message: 'API Secret is required'
  },
  passphrase: {
    required: true,
    trigger: 'blur',
    message: 'Passphrase is required'
  },
  'transactionOption.operator': {
    required: true,
    trigger: 'blur',
    message: 'Transaction data is required'
  },
  value: {
    required: true,
    trigger: 'blur',
    validator: (rule: any, value: any, callback: any) => {
      if (exchangeForm.transactionOption.operator === 'BETWEEN') {
        if (
          (exchangeForm.transactionOption.value.length < 2 && !exchangeForm.transactionOption.value[0],
          !exchangeForm.transactionOption.value[1])
        ) {
          callback(new Error('Date is required'))
        } else if ((exchangeForm.transactionOption.value[0], exchangeForm.transactionOption.value[1])) {
          if (dayjs(exchangeForm.transactionOption.value[0]).isAfter(exchangeForm.transactionOption.value[1])) {
            callback(new Error('Start date must be less than end date'))
          } else {
            callback()
          }
        }
      } else if (exchangeForm.transactionOption.operator !== 'BETWEEN' && !exchangeForm.transactionOption.value[0]) {
        callback(new Error('Date is required'))
      } else {
        callback()
      }
    }
  }
})

const entityId = computed(() => {
  return Number(route.params?.entityId)
})

const currentEntityPermission = computed(() => {
  return reportStore.entityPermission()
})

const disabledSave = computed(() => {
  return (
    (props.model === 'add' &&
      (!exchangeForm.sourceName ||
        !exchangeForm.platformId ||
        !exchangeForm.apiKey ||
        !exchangeForm.apiSecret ||
        (Number(exchangeForm.platformId) === 13 && !exchangeForm.passphrase) ||
        (exchangeForm.transactionOption.operator === 'BETWEEN' &&
          (!exchangeForm.transactionOption.value[0] || !exchangeForm.transactionOption.value[1])) ||
        (exchangeForm.transactionOption.operator !== 'ALL' && !exchangeForm.transactionOption.value[0]))) ||
    (props.model === 'edit' &&
      exchangeForm.sourceName === props.currentData.sourceName &&
      exchangeForm.apiKey === props.currentData.apiKey &&
      exchangeForm.apiSecret === props.currentData.apiSecret &&
      exchangeForm.passphrase === props.currentData.passphrase &&
      exchangeForm.transactionOption.operator === props.currentData?.transactionOption?.operator &&
      isEqual(exchangeForm.transactionOption.value, props.currentData?.transactionOption?.value)) ||
    (exchangeForm.transactionOption.operator === 'BETWEEN' &&
      (!exchangeForm.transactionOption.value[0] || !exchangeForm.transactionOption.value[1])) ||
    (exchangeForm.transactionOption.operator !== 'ALL' && !exchangeForm.transactionOption.value[0])
  )
})

// 切换删除弹窗展示
const onCheckExchangeDialog = () => {
  showExchangeDialog.value = !showExchangeDialog.value
}

const onCloseDialog = () => {
  exchangeFormRef.value?.resetFields()
}

const onChangeOperator = () => {
  exchangeForm.transactionOption.value = []
}

const onOpenStartDateTime = () => {
  visibleStartPopover.value = !visibleStartPopover.value
  startArrowRotate.value = visibleStartPopover.value ? 'rotate(-90deg)' : 'rotate(90deg)'
  if (visibleStartPopover.value) {
    unref(datePickerStartPopoverRef)?.handleOpen?.()
  } else {
    unref(datePickerStartPopoverRef)?.handleClose?.()
  }
}

const onCloseStartDateTime = () => {
  visibleStartPopover.value = false
  unref(datePickerStartPopoverRef)?.handleClose?.()
  startArrowRotate.value = 'rotate(90deg)'
}

const onOpenEndDateTime = () => {
  visibleEndPopover.value = !visibleEndPopover.value
  endArrowRotate.value = visibleEndPopover.value ? 'rotate(-90deg)' : 'rotate(90deg)'
  if (visibleEndPopover.value) {
    unref(datePickerEndPopoverRef)?.handleOpen?.()
  } else {
    unref(datePickerEndPopoverRef)?.handleClose?.()
  }
}

const onCloseEndDateTime = () => {
  visibleEndPopover.value = false
  unref(datePickerEndPopoverRef)?.handleClose?.()
  endArrowRotate.value = 'rotate(90deg)'
}

const onChangeDate = (scope: string) => {
  if (scope === 'start') {
    visibleStartPopover.value = false
    startArrowRotate.value = 'rotate(90deg)'
  } else {
    visibleEndPopover.value = false
    endArrowRotate.value = 'rotate(90deg)'
  }
  exchangeFormRef.value?.validateField('value')
}

const onClickConnect = async () => {
  if (!exchangeFormRef.value) return
  await exchangeFormRef.value.validate(async (valid) => {
    if (valid) {
      try {
        submitLoading.value = true
        const data: any =
          Number(exchangeForm.platformId) === 13
            ? pick(exchangeForm, ['type', 'sourceName', 'purpose', 'platformId', 'apiSecret', 'apiKey', 'passphrase'])
            : pick(exchangeForm, ['type', 'sourceName', 'purpose', 'platformId', 'apiSecret', 'apiKey'])

        if (
          (props.model === 'edit' &&
            exchangeForm.transactionOption.operator !== props.currentData.transactionOption?.operator) ||
          props.model === 'add'
        ) {
          data.transactionOption = {
            operator: exchangeForm.transactionOption.operator,
            value: exchangeForm.transactionOption.operator === 'ALL' ? null : exchangeForm.transactionOption.value
          }
        }

        if (props.model === 'edit') {
          if (!currentEntityPermission.value?.dataSource?.update) {
            ElMessage.warning(t('message.noPermission'))
            return
          }
          const params = pick(data, ['sourceName', 'apiSecret', 'apiKey', 'passphrase', 'transactionOption'])
          await ReportsApi.editSource(entityId.value, props.currentData?.sourceId, params)
        } else {
          if (!currentEntityPermission.value?.dataSource?.create) {
            ElMessage.warning(t('message.noPermission'))
            return
          }
          await ReportsApi.addSource(entityId.value, data)
        }
        const syncData = {
          entityId: entityId.value,
          sourceName: exchangeForm.sourceName,
          type: 'EXCHANGE'
        }
        exchangeFormRef.value?.resetFields()
        showExchangeDialog.value = false
        ElMessage.success(t('message.saveSuccess'))
        await reportStore.fetchSourceList(entityId.value)
        if (props.model === 'add' || (props.model === 'edit' && data?.transactionOption?.operator)) {
          reportStore.editSourceSync(entityId.value, true, syncData)
          emitter.emit('onStartSync')
        }
      } catch (error: any) {
        ElMessage.error(error?.message)
        console.log(error)
      } finally {
        submitLoading.value = false
      }
    }
  })
}

defineExpose({ onCheckExchangeDialog, showExchangeDialog })

watchEffect(() => {
  if (props.model === 'edit') {
    exchangeForm.purpose = props.currentData?.purpose
    exchangeForm.platformId = props.currentData?.platformId
    exchangeForm.sourceName = props.currentData?.sourceName
    exchangeForm.apiKey = props.currentData?.apiKey
    exchangeForm.apiSecret = props.currentData?.apiSecret
    exchangeForm.passphrase = props.currentData?.passphrase
    exchangeForm.transactionOption.operator = props.currentData?.transactionOption?.operator ?? 'ALL'
    exchangeForm.transactionOption.value =
      props.currentData?.transactionOption?.value === null ? [] : cloneDeep(props.currentData?.transactionOption?.value)
  }
})
</script>

<style lang="scss">
.elv-source-exchange-dialog {
  width: 620px;
  min-height: 565px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-source-exchange-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 44px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
      margin: 0;
    }

    .el-input {
      width: 572px;
      height: 44px;
      border-radius: 4px;

      &.is-disabled {
        background: #f9fafb;
      }
    }

    .elv-source-data-short-select {
      .el-input {
        width: 282px;
      }
    }

    .elv-source-data-shorter-select {
      .el-input {
        width: 178px;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-form-item {
      margin-bottom: 16px;

      &.elv-source-exchange-form-item-date {
        .el-form-item__content {
          width: 387px;
          margin-left: 8px;
          justify-content: space-between;
        }
      }

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }

      .el-form-item__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;
      }
    }

    .el-select {
      .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    .elv-source-dialog-exchange-prefix {
      width: 20px;
      height: 20px;
      display: block;
    }

    .elv-source-data-select-content {
      display: flex;
      align-items: flex-end;

      .el-form-item {
        margin-bottom: 0px;
      }

      .elv-source-exchange-date-select {
        box-sizing: border-box;
        height: 44px;
        width: 282px;
        padding: 8px 12px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #ffffff;
        border: 1px solid #dde1e6;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        position: relative;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition-property: border, box-shadow;

        &.is-short {
          width: 178px;
        }

        > div {
          display: flex;
          flex-direction: column;

          p {
            font-family: 'Plus Jakarta Sans';
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            color: #aaafb6;
            margin-bottom: 8px;
          }

          span {
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            color: #0e0f11;
          }
        }

        &:hover,
        &.is-focus {
          cursor: pointer;
          border: 1px solid #5e85eb;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);

          svg {
            fill: #5e85eb;
          }
        }

        svg {
          position: absolute;
          right: 12px;
          bottom: 12px;
          fill: #838d95;
          transition: transform 0.3s;
        }

        .elv-source-exchange-date-time-value {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .elv-source-exchange-date-scope {
        color: #000;
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
      }
    }
  }

  .el-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
  }
}

.elv-source-exchange-date-popper {
  &.el-popper {
    transform: translateY(-11.5px);
    border: 1px solid #e4e7eb;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }

  .el-picker-panel__body-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  .el-date-range-picker__content.is-left {
    border-right: 0px;
  }

  .el-date-range-picker .el-picker-panel__content {
    padding: 8px;
  }

  .el-date-picker {
    width: 237px;

    .el-picker-panel__content {
      width: 221px;
      margin: 0;
      margin-top: 6px;

      .el-date-table {
        th {
          min-width: 31.57px;
          height: 16px;
          border-bottom: 0px;
          padding: 0px;
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 11px;
          line-height: 11px;
          color: #aaafb6;
        }

        tr {
          color: #636b75;
        }

        td {
          padding: 0px;
          width: 31.57px;
          height: 27.8px;
          border-radius: 2px;

          &.today .el-date-table-cell__text {
            color: #1e2024;
          }

          &.available:hover {
            border-radius: 2px;
            background: #edf0f3;

            .el-date-table-cell__text {
              color: #1e2024;
              font-weight: 600;
            }
          }

          &.next-month {
            display: none;

            &:hover {
              border-radius: 2px;
              background: #edf0f3;

              .el-date-table-cell__text {
                color: #1e2024;
                font-weight: 600;
              }
            }
          }

          &.prev-month {
            visibility: hidden;

            &:hover {
              border-radius: 2px;
              background: #edf0f3;

              .el-date-table-cell__text {
                color: #1e2024;
                font-weight: 600;
              }
            }
          }

          .el-date-table-cell {
            padding: 0px;
            width: 31.57px;
            height: 27.8px;

            .el-date-table-cell__text {
              width: 31.57px;
              height: 27.8px;
              font-family: 'Barlow';
              font-weight: 500;
              font-size: 13px;
              line-height: 13px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 0px;
            }
          }

          &.in-range .el-date-table-cell {
            background-color: #fff;
          }

          &.current .el-date-table-cell {
            margin-left: 0px;
            border-radius: 0px;

            .el-date-table-cell__text {
              color: #0e0f11;
              font-weight: 600;
              background-color: #fff;
              box-sizing: border-box;
              border-bottom: 1px solid #1343bf;
            }
          }
        }
      }
    }

    .el-picker-panel__body {
      padding: 8px;
    }

    .el-date-picker__header {
      margin: 0;
    }

    .el-date-picker__header-label {
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 14px;
      color: #aaafb6;
      padding: 0;
      position: relative;
      top: 4px;

      &:nth-child(3) {
        font-weight: 700;
        color: #1e2024;
        margin-left: 4px;
      }
    }
  }

  .el-date-range-picker {
    &.has-time {
      width: 392px;
      min-height: 245px;
      max-height: 273px;
      overflow: hidden;
      background: #ffffff;
    }

    .el-picker-panel__body {
      min-width: 392px;
    }
  }

  .el-picker-panel__icon-btn {
    margin-top: 10.5px;
  }

  .el-date-range-picker__header,
  .el-date-picker__header {
    height: 34px;
    border-bottom: 1px solid #edf0f3;

    div {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 34px;
      color: #1e2024;
      margin: 0px;
    }
  }

  .el-picker-panel__sidebar {
    display: flex;
    border: 0px;
    bottom: -30px;
    left: 8px;
    padding-top: 0;
    z-index: 1;
    height: 21px;
    width: auto;
    top: auto;

    .el-picker-panel__shortcut {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding: 4px 10px;
      min-width: 65px;
      max-width: 73px;
      width: auto;
      height: 21px;
      border: 1px solid #d0d4d9;
      border-radius: 11px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      color: #636b75;
      margin-right: 4px;

      &:hover {
        background: #e6edff;
        border: 1px solid #5e85eb;
        color: #1753eb;
        font-weight: 600;
      }
    }
  }

  .el-picker-panel__body {
    margin-left: 0;
    height: auto;

    .el-date-range-picker__time-header {
      display: none;
    }
  }

  .el-date-table {
    th {
      min-width: 25.71px;
      height: 16px;
      border-bottom: 0px;
      padding: 0px;
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 11px;
      line-height: 11px;
      color: #aaafb6;
    }

    tr {
      color: #636b75;
    }

    td {
      padding: 0px;
      width: 25.71px;
      height: 27.8px;
      border-radius: 2px;

      &.today .el-date-table-cell__text {
        color: #1e2024;
      }

      &.available:hover {
        border-radius: 2px;
        background: #edf0f3;

        .el-date-table-cell__text {
          color: #1e2024;
          font-weight: 600;
        }
      }

      &.next-month {
        display: none;

        &:hover {
          border-radius: 2px;
          background: #edf0f3;

          .el-date-table-cell__text {
            color: #1e2024;
            font-weight: 600;
          }
        }
      }

      &.prev-month {
        visibility: hidden;

        &:hover {
          border-radius: 2px;
          background: #edf0f3;

          .el-date-table-cell__text {
            color: #1e2024;
            font-weight: 600;
          }
        }
      }

      .el-date-table-cell {
        padding: 0px;
        width: 25.71px;
        height: 27.8px;

        .el-date-table-cell__text {
          width: 25.71px;
          height: 27.8px;
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 13px;
          line-height: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0px;
        }
      }

      &.in-range .el-date-table-cell {
        background-color: #fff;
      }

      &.start-date .el-date-table-cell {
        margin-left: 0px;
        border-radius: 0px;

        .el-date-table-cell__text {
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }

      &.end-date .el-date-table-cell {
        margin-right: 0px;
        border-radius: 0px;

        .el-date-table-cell__text {
          border-right: 0px;
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }
    }
  }

  .el-picker-panel__footer {
    padding: 0px;
    margin: 0 8px;
    padding-top: 8px;
    box-sizing: border-box;
    height: 37px;

    .el-picker-panel__link-btn {
      height: 21px;
      margin-top: -7px;
    }
  }
}

.elv-source-dialog-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0e0f11;

    &.hover {
      background: #f9fafb;
    }

    .elv-source-dialog-exchange-option {
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 20px;
        height: 20px;
        filter: drop-shadow(0px 1px 3px rgba(33, 27, 78, 0.15));
        margin-right: 10px;
      }

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #0e0f11;
        display: flex;
        align-items: center;
      }

      span {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 4px;
        height: 18px;
        border: 1px solid #dde1e6;
        border-radius: 3px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        color: #d0d4d9;
      }
    }
  }
}
</style>
