<template>
  <div class="elv-source-noData-container">
    <template v-if="props.type === t('common.wallets')">
      <div class="elv-source-noData-img__wallet">
        <img src="@/assets/img/reports/source-ethereum.png" alt="ethereum" />
        <img src="@/assets/img/reports/source-bitcoin.png" alt="bitcoin" />
        <img src="@/assets/img/reports/source-binanceCoin.png" alt="binanceCoin" />
      </div>
      <h3>{{ t('report.addYourWallet') }}</h3>
      <p class="elv-source-noData-info">{{ t('report.addWalletInfo') }}</p>
      <p class="elv-source-noData-help">
        <img src="@/assets/img/help.png" alt="help" /><span @click="onLearnMore">{{ t('button.learnMore') }}</span>
      </p>
      <elv-button width="147" round @click="onClickAdd">{{ t('button.addWallet') }}</elv-button>
    </template>

    <template v-if="props.type === t('common.exchanges')">
      <div class="elv-source-noData-img__exchange">
        <img src="@/assets/img/reports/source-binance.png" alt="binance" />
        <img src="@/assets/img/reports/source-coinbase.png" alt="coinbase" />
        <img src="@/assets/img/reports/source-okx.png" alt="okx" />
      </div>
      <h3>{{ t('report.addYourExchange') }}</h3>
      <p class="elv-source-noData-info">{{ t('report.addExchangeInfo') }}</p>
      <p class="elv-source-noData-help">
        <img src="@/assets/img/help.png" alt="help" /><span @click="onLearnMore">{{ t('button.learnMore') }}</span>
      </p>
      <elv-button width="147" round @click="onClickAdd">{{ t('button.addExchange') }}</elv-button>
    </template>

    <template v-if="props.type === 'Files'">
      <div class="elv-source-noData-img__csv">
        <img src="@/assets/img/reports/source-bank-HSBC.png" alt="HSBC" />
        <img src="@/assets/img/reports/source-CSV.png" alt="csv" />
        <img src="@/assets/img/reports/source-bank.png" alt="bank" />
      </div>
      <h3>{{ t('report.uploadCSVFile') }}</h3>
      <p class="elv-source-noData-info">{{ t('report.uploadCSVFileInfo') }}</p>
      <p class="elv-source-noData-help">
        <!-- <img src="@/assets/img/help.png" alt="help" /><span @click="onLearnMore">{{ t('button.learnMore') }}</span> -->
      </p>
      <elv-button width="147" round @click="onClickAdd">{{ t('button.upLoadCSV') }}</elv-button>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const props = defineProps({
  type: {
    type: String,
    default: 'Wallets'
  }
})

const { t, locale } = useI18n()

const emit = defineEmits(['add'])

const onLearnMore = () => {
  switch (props.type) {
    case t('common.wallets'):
      window.open(
        locale.value === 'en'
          ? 'https://docs.elven.com/english/guides-and-tutorials/add-source#i.-add-an-on-chain-wallet'
          : 'https://docs.elven.com/chinese/guides-and-tutorials/tian-jia-shu-ju-yuan#yi-tian-jia-lian-shang-qian-bao',
        '_blank'
      )
      break
    case t('common.exchanges'):
      window.open(
        locale.value === 'en'
          ? 'https://docs.elven.com/english/guides-and-tutorials/add-source#ii.-add-exchange-api'
          : 'https://docs.elven.com/chinese/guides-and-tutorials/tian-jia-shu-ju-yuan#er-tian-jia-jiao-yi-suo-api',
        '_blank'
      )
      break
    default:
      break
  }
}

const onClickAdd = () => {
  emit('add', props.type)
}
</script>

<style lang="scss" scoped>
.elv-source-noData-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 870px;
  height: 258px;
  background: #f9fafb;
  border: 1px solid #edf0f3;
  border-radius: 4px;
  transition: all 0.2s;

  &:hover {
    border-color: #4674eb;
    background-color: #fff;

    .elv-source-noData-img__wallet,
    .elv-source-noData-img__exchange,
    .elv-source-noData-img__csv {
      img {
        &:nth-child(1) {
          left: calc(50% - 69.62px);
          bottom: 7.99px;
          transform: translate(0px, -8.28px);
          overflow: visible;
        }

        &:nth-child(2) {
          background: #ffffff;
          left: calc(50% - 16px);
          bottom: 26px;
          overflow: hidden;
        }

        &:nth-child(3) {
          background: #ffffff;
          left: calc(50% - -39.01px);
          bottom: 16.62px;
          transform-origin: 0 0;
          transform: rotate(15deg) scale(1, 1);
          overflow: hidden;
        }
      }
    }
  }

  .elv-source-noData-img__wallet,
  .elv-source-noData-img__exchange,
  .elv-source-noData-img__csv {
    width: 190px;
    height: 60px;
    position: relative;

    img {
      width: 32px;
      height: 32px;
      display: block;
      border-radius: 50px;
      transition: all 0.2s;
      position: absolute;

      &:nth-child(1) {
        left: calc(50% - 58.33px);
        bottom: -0.02px;
        transform: translate(0px, -5.01px);
        overflow: visible;
      }

      &:nth-child(2) {
        background: #ffffff;
        left: calc(50% - 16px);
        bottom: 11px;
        overflow: hidden;
      }

      &:nth-child(3) {
        background: #ffffff;
        left: calc(50% - -27.02px);
        bottom: 5.33px;
        // transform-origin: 0 0;
        // transform: rotate(9deg) scale(1, 1);
        // overflow: hidden;
      }
    }
  }

  h3 {
    margin: 10px 0;
    font-family: 'Plus Jakarta Sans';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #0e0f11;
  }

  .elv-source-noData-info {
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #636b75;
  }

  .elv-source-noData-help {
    display: flex;
    align-items: center;
    margin: 10px 0;

    img {
      width: 16px;
      height: 16px;
      display: block;
      margin-right: 4px;
    }

    span {
      cursor: pointer;
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      text-decoration-line: underline;
      color: #5e85eb;
    }
  }
}
</style>
